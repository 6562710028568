var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "document-slide-component" } },
    [
      _c("slide-heading", {
        staticClass: "document-slide-heading",
        attrs: { heading: _vm.slide.heading, "tool-tip": _vm.slide.tool_tip },
      }),
      _c("slide-image-and-description", { attrs: { slide: _vm.slide } }),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "w-100" },
            [
              _vm.slideAgencyResource && _vm.resourceFields.length
                ? _c("stageline-side-by-side-viewer", {
                    ref: "stageline-side-by-side-viewer",
                    attrs: {
                      id: "document-viewer",
                      "read-only":
                        _vm.makeReadOnly && !_vm.debugDocumentViewerEditMode,
                    },
                    on: {
                      completed: _vm.documentComplete,
                      "previous-slide": _vm.previousSlide,
                      "show-contact-modal": _vm.showContactModal,
                    },
                  })
                : _c("no-document-found", {
                    attrs: { slide: _vm.slide },
                    on: {
                      "next-slide": function ($event) {
                        return _vm.$emit("next-slide")
                      },
                    },
                  }),
              _vm.canEditCompanyDetails() && _vm.loaded
                ? _c(
                    "div",
                    { staticClass: "change-data-wrapper" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "change-data",
                          attrs: {
                            variant: "default",
                            "aria-label": "wrong document button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "change-data",
                                _vm.companyStructureSlide
                              )
                            },
                          },
                        },
                        [
                          _c("fa-icon", {
                            staticClass: "fa-xs",
                            attrs: { icon: "chevron-left" },
                          }),
                          _vm._v(
                            " \n        Wrong document? Change company structure\n      "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ]),
      _c("contact-modal", {
        ref: "contact-modal",
        attrs: { title: "Add a contact", type: "add" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }