<template>
  <div id="document-slide-component">
    <slide-heading
      :heading="slide.heading"
      :tool-tip="slide.tool_tip"
      class="document-slide-heading"
    />

    <slide-image-and-description :slide="slide" />

    <div v-if="loaded" class="w-100">
      <stageline-side-by-side-viewer
        v-if="slideAgencyResource && resourceFields.length"
        id="document-viewer"
        ref="stageline-side-by-side-viewer"
        :read-only="makeReadOnly && !debugDocumentViewerEditMode"
        @completed="documentComplete"
        @previous-slide="previousSlide"
        @show-contact-modal="showContactModal"
      />

      <no-document-found
        v-else
        :slide="slide"
        @next-slide="$emit('next-slide')"
      />

      <!-- Can probably do something similar for changing the jurisdiction on form a company -->
      <div
        v-if="canEditCompanyDetails() && loaded"
        class="change-data-wrapper"
      >
        <b-button
          class="change-data"
          variant="default"
          aria-label="wrong document button"
          @click="$emit('change-data', companyStructureSlide)"
        >
          <fa-icon icon="chevron-left" class="fa-xs" />&nbsp;
          Wrong document? Change company structure
        </b-button>
      </div>
    </div>

    <ct-centered-spinner v-else>
      {{ loadingText }}
    </ct-centered-spinner>

    <contact-modal
      ref="contact-modal"
      :title="'Add a contact'"
      :type="'add'"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DocumentSlide',
  components: {
    StagelineSideBySideViewer: () => import('./StagelineSideBySideViewer'),
    ContactModal:              () => import('@/components/ContactModal'),
    CtCenteredSpinner:         () => import('@/components/shared/CtCenteredSpinner'),
    NoDocumentFound:           () => import('@/components/StagelineV2/slides/document/NoDocumentFound'),
    SlideHeading:              () => import('@/components/StagelineV2/shared/SlideHeading'),
    SlideImageAndDescription:  () => import('@/components/StagelineV2/shared/SlideImageAndDescription.vue'),
  },
  props: {
    slide: null,
    readOnly: null,
  },
  data() {
    return {
      loaded: false,
      loadingText: 'Loading document...',
      formDataSet: false,
      companyStructureSlide: 'llc_company_structure__company_structure',
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'resourceFields',
      'debugDocumentViewerEditMode',
      'formData',
    ]),
    ...mapGetters('stageline', [
      'company',
      'currentSlide',
      'formACompanyFiling',
      'slideAgencyResource',
      'isFinalizeLegalDocs',
      'isLazyLoadedDocType',
    ]),
    makeReadOnly() {
      return (
        this.currentSlide?.layout_sub_type === 'form a company' &&
        !!this.formACompanyFiling
      ) || this.readOnly
    },
  },
  watch: {
    formData(value) {
      if (Object.keys(value).length && !this.formDataSet) {
        this.formDataSet = true
        if (this.isLazyLoadedDocType) {
          this.saveResourceToAccount({ ...this.formData, upload_template: true })
        }
      }
    },
  },
  async mounted() {
    this.loaded = false

    this.$emit('collapseSidebar')
    this.addContinueButtonIfDocumentReadOnly()
    if (this.isLazyLoadedDocType) await this.loadResourceByType()

    if (this.slideAgencyResource) {
      this.resetStagelineSchemaForm()
      this.setResource(this.slideAgencyResource)
      await this.loadResourceFieldSchema({
        resourceId: this.slideAgencyResource.id,
      })
    }

    this.loaded = true
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'resetStagelineSchemaForm',
      'setResource',
      'loadResourceFieldSchema',
      'updateFormData',
      'processFormData',
    ]),
    ...mapActions('stageline', [
      'setCurrentSlide',
      'loadResourceByType',
      'saveResourceToAccount',
    ]),
    async documentComplete() {
      await this.updateFormData()
      this.loadingText = 'Saving Form...'
      this.loaded = false
      await this.processFormData()
      this.$emit('document-complete')
      this.loaded = true
    },
    previousSlide() {
      this.$emit('previous-slide')
    },
    addContinueButtonIfDocumentReadOnly() {
      if (!this.makeReadOnly) return
      const slide = this.currentSlide
      this.slide.config.continue_button.hide = false
      this.setCurrentSlide({ slide })
    },
    canEditCompanyDetails() {
      return this.company?.entity_type === 'Limited Liability Company' && this.isFinalizeLegalDocs
    },
    showContactModal() { // < safe to remove when fully on new form
      this.$refs['contact-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>

#document-slide-component {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: row;
  min-height: 60%;

  .stageline-side-by-side-container {
    display: flex;
  }
  .document-slide-heading {
    font-size: 1.75em;
  }
  .change-data-wrapper {
    width: 45%;
  }
  .change-data {
    float: right;
  }

  @media only screen and (max-width: 767px) {
    .change-data-wrapper {
      width: 100%;
    }
  }
}

</style>
