import { render, staticRenderFns } from "./DocumentSlide.vue?vue&type=template&id=30200bce&scoped=true"
import script from "./DocumentSlide.vue?vue&type=script&lang=js"
export * from "./DocumentSlide.vue?vue&type=script&lang=js"
import style0 from "./DocumentSlide.vue?vue&type=style&index=0&id=30200bce&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30200bce",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30200bce')) {
      api.createRecord('30200bce', component.options)
    } else {
      api.reload('30200bce', component.options)
    }
    module.hot.accept("./DocumentSlide.vue?vue&type=template&id=30200bce&scoped=true", function () {
      api.rerender('30200bce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StagelineV2/slides/document/DocumentSlide.vue"
export default component.exports